// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "fancybox/jquery.fancybox.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
//@import "connectiv/custom-font.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
@import "font-awesome/font-awesome.scss";

//Slick Slider
@import "slick/slick.scss";
@import "slick/slick-theme.scss";

//Bootstrap Select
@import "bootstrap-select/variables.scss";
//@import "bootstrap-select/bootstrap-select.scss";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

//Sonstiges
@import "connectiv/custom-mobile-nav.scss";

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

body {
  font-family: 'Roboto', sans-serif;;
  overflow-x: hidden;
  font-weight: 300;
}

//.btn {
//  line-height: 1.42857143; //fixes SASS number precision issue
//}
//
//$brand-height: 52px; //Höhe der Logografik
//$brand-padding-vertical: (($navbar-height - $brand-height) / 2);
//
//.container-fluid {
//  max-width: 1000px + $grid-gutter-width;
//}

.logo-small {
  display: none;
}

h1, h2, h3, h4 {
  margin-top: 0;
  font-weight: 300;
}

h1 {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 300;
  color: $brand-primary;
  margin-bottom: 25px;
}

h2 {
  color: $brand-primary;
  display: block;
  font-size: 25px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

h3 {
  font-size: 17px;
  text-transform: uppercase;
  color: $brand-primary;
  display: block;
  margin-bottom: 10px;
}

#content {
  position: relative;
}

#content p, .content p {
  font-size: 17px;
  line-height: 32px;
  color: $grey;
  margin-bottom: 20px;
}

.randleiste-oben {
  min-height: 30px;
  position: relative;
  > p {
    margin: 0!important;
  }
}

.randleiste-unten {
  position: relative;
  > p {
    margin: 0!important;
  }
}

.logo {
  top: 60px;
  display: inline-block;
  position: absolute;
  margin-left: -30px;
}

.lang-switch {
  list-style-type: none;
  float: right;
  margin-top: 15px;
  display: block;
}

.lang-switch__item {
  display: inline-block;
  float: left;
}

.lang-switch__item a {
  color: $light-blue;
  font-size: 16px;
  text-transform: uppercase;
  &:hover, &:focus, &.active {
    text-decoration: underline;
  }
}

.lang-switch__item:not(:last-child):after {
  margin-right: 5px;
  margin-left: 5px;
  content: '|';
  color: $light-blue;
  top: -1px;
  position: relative;
}

.header {
  background-image: url(../img/logo-Linien-1px.png);
  background-repeat: repeat-x;
  background-position-y: 60px;
  background-position-x: center;
  height: 150px;
}

.themepic.video {
  display: none;
}

//.themepic.folge {
//  height: 770px;
//  background-position: center center;
//  background-repeat: no-repeat;
//}

.themepic-mobile-fallback {
  width: 100%;
}

.themepic {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.themepic-gradient {
  background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  height: 380px;
  margin-top: -380px;
}

.slider .slick-dots {
  bottom: 50px;
  z-index: 300;
}

.themepic-slider__item {
  outline: none;
}

.themepic-slider__item-content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.themepic-slider__item-title {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 50px;
  display: block;
  color: #fff;
}


.themepic-slider__item-btn {
  font-size: 25px;
  line-height: 32px;
  color: #fff;
  margin-top: 40px;
  display: inline-block;
  min-width: 129px;
  background-image: url(../img/welle-links-weiss.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 66px;
  margin-bottom: 50px;
  &:before {
    display: block;
    content: '';
    height: 14px;
  }
  &:hover {
    color: #fff;
    &:before {
      background-image: url(../img/welle-links-weiss-hover.svg);
      background-repeat: no-repeat;
      background-position: center top;
    }
  }
}

.themepic-slider-blue {
  z-index: 900;
  padding-top: 50px;
  margin: -320px auto 0 auto;
  height: 320px;
  background-color: rgba(0, 139, 207, 0.8);
}
.themepic-slider-blue__img {
  padding-left: 40px;
  padding-right: 40px;
  img {
    max-width: 170px;
  }
}
.themepic-slider-blue__item-content {
  color: #fff;
  padding-right: 40px;
}
.themepic-slider-blue__item-title {
  font-weight: 300;
  font-size: 50px;
  text-transform: uppercase;
  line-height: 50px;
  margin-bottom: 20px;
  display: block;
}
.themepic-slider-blue__item-text {
  color: #fff;
  font-size: 17px;
  line-height: 34px;
}
.themepic-slider-outer .slick-dots {
  bottom: 20px;
}

.themepic-slider__dot {
  background-image: url(../img/bildergalerie-punkt-weiss.svg);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  height: 25px;
  width: 25px;
}

.slick-active {
  //themepic
  .themepic-slider__dot {
    background-image: url(../img/bildergalerie-punkt-weiss-aktiv.svg);
  }
}

.section {
  position: relative;
  margin-bottom: 50px;
}

.section--text, .icon-stage__content {
  max-width: 840px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.icon-stage__content {
  text-align: center;
}

.section--benefits {
  background-color: #19a6db;
  padding-top: 60px;
  text-align: center;
}

.section__headline--benefits {
  color: #fff!important;
  margin-bottom: 0;
}

.section--products, .section--productcarousel {
  text-align: center;
}

#content ul {
  font-size: 17px;
  color: #707173;
  line-height: 32px;
  margin-bottom: 0;
}

.news-box {
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  position: relative;
  color: #fff;
  width: 100%;

  p {
    color: #fff !important;
  }
}

.news-box__content {
  display: block;
  z-index: 100;
  position: relative;
  max-width: 430px;
  padding: 15px;
  margin: 25px auto 25px auto;
  text-align: center;
}

.news-box__date {
  display: block;
  font-weight: 700;
  font-style: italic;
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 20px;
}

.news-box__title {
  display: block;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.news-box__text {
  font-size: 17px;
}

.news-box:hover {
  color: #fff;
  .news-box__layer {
    transition: background-color 0.5s;
    background-color: rgba(0, 139, 207, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .news-box__content {
    display: block;
  }
}

.section__headline {
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 300;
  color: $brand-primary;
  margin-bottom: 25px;
  display: block;
  line-height: 50px;
}

.product-list__item {
  padding-top: 20px;
  padding-bottom: 60px;
  margin: 0;
  outline: none!important;
  display: block;
  &:hover {
    color: $brand-primary;
  }
}

.product-list__item {
  .product-list__item-title:before {
    display: block;
    content: '';
    height: 14px;
  }
  &:hover {
    .product-list__item-title:before {
      background-image: url('../img/welle-links-hover.svg');
      background-repeat: no-repeat;
      background-position: center top;
    }
  }
}

.product-list__item-title {
  font-size: 25px;
  display: block;
  text-transform: uppercase;
  background-image: url('../img/welle-links.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  line-height: 32px;
  //padding-bottom: 18px;
  padding-bottom: 15px;
  font-weight: 300;
}

.product-list__item-image {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

.rfq {
  display: inline-block;
  width: 201px;
  height: 83px;
  background-image: url(../img/reiter-angebot-einholen.svg);
  background-repeat: no-repeat;
  color: #fff;
  padding-left: 100px;
  padding-top: 21px;
  font-weight: 700;
  &:hover,&:active,&:focus {
    color: #fff;
  }
}

.icon-stage {
  background-position: center 200px, center center;
  background-repeat: no-repeat;
  p {
    font-size: 17px;
    line-height: 32px;
  }
}

.icon-stage__btn:before {
  display: block;
  content: '';
  height: 14px;
  position: relative;
  top: -5px;
}

.icon-stage__btn:hover:before {
  background-repeat: no-repeat;
  background-position: center top;
}

.icon-stage--white-text {
  background-color: #19a6db;
  background-image: url(../img/icon-wellenlinie-linkboxen.svg), url(../img/himmel-blau.jpg);
  .section__headline, p, a {
    color: #fff!important;
  }
  .icon-stage__btn {
    background-image: url(../img/welle-links-weiss.svg);
    color: #fff;
    &:hover:before {
      background-image: url('../img/welle-links-weiss-hover.svg');
    }
  }
}

.icon-stage--blue-text {
  background-color: #fff;
  background-image: url(../img/icon-wellenlinie-linkboxen-blau.svg), url(../img/himmel.jpg);
  .section__headline, p, a {
    color: $brand-primary!important;
  }
  .icon-stage__btn {
    background-image: url(../img/welle-links.svg);
    &:hover:before {
      background-image: url('../img/welle-links-hover.svg');
    }
  }
}

.icon-stage__content {
  padding-top: 120px;
  padding-bottom: 140px;
}

.icon-stage__icon {
  margin-bottom: 70px;
}

.icon-stage__btn {
  font-size: 25px;
  line-height: 25px;
  margin-top: 40px;
  display: block;
  background-image: url(../img/welle-links-weiss.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 60px;
}

.downloads-selection {
  margin-bottom: 60px;
}

.no-results {
  text-align: center;
}

.download {
  padding: 0;
  list-style-type: none;
  border-top: 2px solid #fff;
  max-width: 540px;
  margin: 50px auto 0 auto;
}

.download__item a {
  display: block;
  padding: 10px 0;
  border-bottom: 2px solid #fff;
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  &:hover {
    background-color: $light-blue;
  }
}

#results {
  margin-bottom: 100px;
}

.result-list {
  list-style-type: none;
  padding: 0;
  border-top: 1px solid $brand-primary;
  margin-bottom: 50px!important;
}

.result-list__item {
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid $brand-primary;
  font-size: 25px;
}

.result-list__item-checkbox {
  appearance: none;
  border:none;
  border-radius: 0;
  font-size: 1em;
  width: 100%
}

//ie8
.result-list__item-checkbox {
  width:auto;
  float:left;
  margin-right: .75em;
  background:transparent;
  border:none;
}
.result-list__item-checkbox:checked,
.result-list__item-checkbox:not(:checked) {
  background: transparent;
  position: relative;
  visibility: hidden;
  margin:0;
  padding:0;
}

.result-list__item-checkbox + label {
  cursor: pointer;
}

.result-list__item-checkbox + label:before,
.result-list__item-checkbox:not(:checked) + label:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  position: relative;
  top: -1px;
  border: 1px solid $brand-primary;
  background: #fff;
  margin-right: 15px;
  box-shadow: inset 0 1px 1px 0 rgba(0,0,0,.1);
}

.result-list__item-checkbox:hover + label:before {
  background: $brand-primary;
  box-shadow: inset 0 0 0 2px #fff;
}

.result-list__item-checkbox:checked + label:before {
  background: $brand-primary;
  box-shadow: inset 0 0 0 2px #fff;
}

.result-list__item label {
  margin-bottom: 0;
  font-weight: normal;
}

.result-list__item-type {
  text-transform: uppercase;
}

.result-list__item-icon {
  display: block;
  width: 25px;
  height: 27px;
  float: right;
  background-image: url(../img/icon-download-klein.svg);
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 2px;
  &:hover {
    background-image: url(../img/icon-download-klein-hover.svg);
  }
}

.result-list__button {
  width: 100%;
  background-color: $brand-primary;
  border: none;
  color: #fff;
  line-height: 25px;
  font-size: 25px;
  height: 60px;
  &:hover {
    background-color: $light-blue;
  }
}

footer {
  position: relative;
  &:before {
    content:'';
    display: block;
    width: 100%;
    height: 77px;
    position: absolute;
    background-image: url(../img/lasche-blau.png);
    background-repeat: no-repeat;
    background-position: center top;
    top: -75px;
  }
}

.footer__scroll-top {
  background-image: url(../img/icon-pfeil-oben.svg);
  background-repeat: no-repeat;
  background-position: center top;
  display: inline-block;
  width: 37px;
  height: 20px;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  &:hover {
    background-image: url(../img/icon-pfeil-oben-hover.svg);
  }
}

.footer {
  background-color: #1A8AC6;
  background-image: url(../img/welle-footer.svg);
  background-repeat: no-repeat;
  background-position: center 50px;
  //margin-top: 75px;
}

.footer a {
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.footer-item,
.benefits-item {
  background-image: url(../img/icon-wellenlinie-linkboxen.svg);
  background-repeat: no-repeat;
  background-position: center 60px;
  text-align: center;
}

.benefits-item {
  background-position-y: 75px;
}

.benefits-item__title {
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}

.footer-item__icon {
  display: block;
  margin: 0 auto 35px auto;
}

.footer-item__icon--kontakt {
  padding-bottom: 13px;
}

.footer-item__icon--anfahrt {
  padding-bottom: 1px;
}

.benefits-item__icon {
  display: block;
  margin: 0 auto 53px auto;
}

.footer-item__text {
  font-size: 25px;
  color: #fff;
}

.benefits-item__text {
  color: #fff!important;
  font-size: 17px;
  line-height: 32px;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.footer-line {
  padding-top: 24px;
  padding-bottom: 20px;
  background-color: $dark-blue;
}

.footer-line__copyright {
  color: $light-blue;
  font-size: 17px;
  display: inline-block;
  margin-bottom: 10px;
}

.footer-line__nav {
  list-style-type: none;
  padding: 0;
}

.footer-line__nav-item {
  display: inline-block;
  float: left;
  font-size: 17px;
}

.footer-line__nav-item a {
  color: $light-blue;
  &:hover, &.active, &:focus {
    color: #fff;
  }
}

.footer-line__nav-item:not(:last-child):after {
  margin-right: 6px;
  margin-left: 6px;
  content: '|';
  color: $light-blue;
}

//Product Detail
.producthead {
  text-align: center;
  z-index: 900;
  position: relative;
}

.producthead__img {
  display: block;
  max-width: 260px;
  margin: 150px auto 50px auto;
}

.producthead__title {
  font-size: 50px;
  font-weight: 300;
  color: $brand-primary;
  display: block;
  margin-bottom: 25px;
}

.producthead__subtitle {
  display: block;
  font-size: 25px;
  color: $brand-primary;
  margin-bottom: 80px;
}

.producthead__text {
  font-size: 17px;
  color: $grey;
  line-height: 32px;
}

.product {
  margin-bottom: 50px;
}

.product__col {
  position: relative;
}

.product__title {
  font-size: 50px;
  display: block;
  color: $brand-primary;
  font-weight: 300;
  border-bottom: 1px solid $brand-primary;
  margin-bottom: 25px;
  line-height: inherit;
}

.product__figure {
  padding-right: 40px;
}

.product__img {
  width: 360px;
  display: inline-block;
}

.product__imgzoom {
  position: absolute;
  bottom: 0;
  left: 370px;
  display: inline-block;
  width: 26px;
  img {
    height: 26px;
    width: 26px;
  }
}

.product__details {
  margin-top: 36px;
}

.product__attribute {
  color: $brand-primary;
  display: block;
  border-bottom: 1px solid $brand-primary;
  font-size: 25px;
  text-transform: uppercase;
  margin-bottom: 5px;
  line-height: 35px;
}

.product__value {
  display: block;
  font-size: 17px;
  color: $grey;
  margin-bottom: 20px;
}

.blue-select {
  border-radius: 0;
  border-color: $brand-primary;
  height: 65px;
  font-size: 24px;
  color: $brand-primary;
  width: 100%;
  padding-left: 15px;
}

.blue-select {
  appearance: none;
  border:none;
  border-radius: 0;
  width: 100%
}

.blue-select {
  border: 1px solid $brand-primary;
  height: 65px;
  font-size: 24px;
  padding-left: 15px;
  background-color: #fff;
  background-image: url(../img/icon-pfeil-unten-filter.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.blue-select:hover {
  cursor: pointer;
}

.blue-select option {
  color: $grey;
  font-size: 16px;
}

.blue-select:disabled {
  color: rgb(169, 169, 169);
  border-color: rgb(169, 169, 169);
  color: $light-blue;
  border-color: $light-blue;
}

//#reference-form {
//  margin-bottom: 150px;
//}

.ref-mosaic {
  overflow: hidden;
  min-height: 150px;
}

.reference-box {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  position: relative;
  color: #fff;
  width: 100%;
  //min-height: 350px;
  min-height: 300px;
  //background-size: cover;
  //p {
  //  color: #fff !important;
  //}
}

.reference-box__content {
   display: block;
   z-index: 100;
   position: relative;
   max-width: 430px;
   padding: 15px;
   margin: 25px auto 25px auto;
 }

.reference-box:hover {
  color: #fff;
  .reference-box__layer {
    transition: background-color 0.5s;
    background-color: rgba(0, 139, 207, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:after {
      content: '';
      height: 37px;
      width: 21px;
      background-image: url(../img/icon-pfeil-rechts.svg);
      background-repeat: no-repeat;
      background-position: right bottom;
      position: absolute;
      right: 40px;
      bottom: 30px;
    }
  }
  .reference-box__content {
    display: block;
  }
}

.reference-box__title {
  text-transform: uppercase;
  display: block;
  font-size: 28px;
  margin-bottom: 30px;
}

.reference-box__products {
  display: block;
  font-size: 20px;
  margin-bottom: 50px;
}

.reference-box__imageproof {
  display: block;
  font-size: 20px;
}

.reference__attribute {
  color: $brand-primary;
  display: block;
  font-size: 25px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.reference__value {
  display: block;
  font-size: 17px;
  color: $grey;
  margin-bottom: 20px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.reference__gallery-item {
  height: 150px;
  width: 50%;
  float: left;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  color: #fff;
  &:hover {
    .reference__gallery-layer {
      transition: background-color 0.5s;
      background-color: rgba(0, 139, 207, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      > img {
        display: block;
        animation: fadeIn 1s;
      }
    }
  }
}

.reference__gallery-layer > img {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  max-width: 25%;
}

.reference__gallery-item--small {
  width: 25%;
}

.reference__products {
  margin-top: 35px;
  margin-bottom: 50px;
}

.reference__productitem {
  margin-bottom: 15px;
  display: block;
}

.reference__productitem:hover {
  .reference__productitem-title {
    color: $light-blue;
  }
}

.reference__productitem-title {
  color: $grey;
  display: block;
  margin-bottom: 15px;
  font-size: 17px;
  position: relative;
  padding: 0 0 0 10px;
  &:before {
    content: '';
    background-color: $brand-primary;
    display: block;
    width: 2px;
    height: 17px;
    margin-left: -10px;
    position: absolute;
    top: 4px;
  }
}

.reference__back, .product__back {
  font-size: 25px;
}

.product__back {
  display: inline-block;
  margin-top: 40px;
}

.section-img {
  margin-bottom: 20px;
}

.section-text {
  max-width: 720px;
  .section__headline {
    text-align: left;
  }
}

.meldungueber {
  font-size: 17px;
  color: $grey;
  text-transform: uppercase;
}

.search_input {
  border: 1px solid $brand-primary;
  height: 65px;
  font-size: 24px;
  padding-left: 15px;
  float: left;
}

.submit_search {
  border: 1px solid $brand-primary;
  background-color: $brand-primary;
  height: 65px;
  font-size: 24px;
  color: #fff;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.list-group-item-heading {
  margin-top: 40px;
  margin-bottom: 5px;
}

@media (max-width: $screen-xs-max) {


  .section__headline, h1, .news-box__date {
    font-size: 28px;
    line-height: 36px;
    hyphens: auto;
  }
  h2 {
    font-size: 20px;
    hyphens: auto;
  }
  .themepic-slider__item-title {
    font-size: 32px;
    line-height: 32px;
  }
  .themepic-slider__item-btn {
    font-size: 20px;
    height: 62px;
  }
  .product-list__item-title {
    font-size: 20px;
  }
  .icon-stage__btn {
    font-size: 20px;
  }
  .result-list__item {
    font-size: 16px;
    word-break: break-all;
  }
  .result-list__item-checkbox + label:before,
  .result-list__item-checkbox:not(:checked) + label:before {
    top: 3px;
  }
  #content p,
  .content p,
  .producthead__text,
  .product__value,
  .benefits-item__text {
    font-size: 15px;
    line-height: 30px;
  }
  .icon-stage p {
    font-size: 15px;
    line-height: 30px;
  }
  #content ul {
    font-size: 15px;
  }



  .themepic-outer.start {
    padding-bottom: 0!important;
  }
  .icon-stage {
    background-position: center 150px,center center;
  }
  .icon-stage__content {
    padding-top: 60px;
    padding-bottom: 110px;
  }
  .rfq {
    display: none!important;
  }

  .header {
    background-position-y: 20px;
    height: 111px;
  }
  .logo {
    top: 20px;
  }
  .navbar-toggle-outer {
    background-color: $brand-primary;
    height: 50px;
  }
  .nav-outer {
    border-top: 1px solid #fff;
  }
  .themepic-outer {
    margin-bottom: 50px;
    padding-top: 60px;
  }
  .themepic-outer.folge {
    padding-top: 0px;
  }
  .footer-item,
  .benefits-item {
    max-width: 300px;
    margin: 50px auto;
  }
  .section-text {
    padding: 0 20px;
  }
  .producthead__img {
    margin-top: 70px;
  }
  //.reference-box:last-of-type{
  //  margin-bottom: 100px;
  //}
  .reference-box:hover {
    .reference-box__layer {
      &:after {
        right: 15px;
        bottom: 15px;
      }
    }
  }
  .reference-box__products, .reference-box__imageproof {
    display: none;
  }

  .container .section--text {
    padding-left: 0;
    padding-right: 0;
  }

}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  .header {
    height: 126px;
    background-image: url(../img/logo-Linien-1px.png);
    background-repeat: repeat-x;
    //background-position-y: bottom;
    background-position: center 35px;
  }
  .header-inner {
    position: relative;
  }

  .randleiste-oben {
    min-height: 50px;
  }

  .logo {
    margin-top: 35px;
    top: 0;
    display: inline-block;
    z-index: 2000;
    position: relative;
    //margin-left: -60px;
    margin-left: -35px;
  }

  .global {
    float: right;
    margin-top: 20px;
  }
  .global__item {
    display: inline-block;
    float: left;
  }
  .global__item.search {
    margin-right: -6px;
  }
  .global__item {
    background: none;
    border: none;
    outline: none;
    img {
      float: left;
    }
  }
  .global__itemcontent {
    font-size: 14px;
    display: block;
    background-color: #FFF;
    color: $light-blue;
    width: 0;
    transition: all .5s .2s;
    overflow: hidden;
    border: none;
    white-space: nowrap;
    &:hover {
      color: $light-blue;
    }
  }
  .global__item:focus .global__itemcontent,
  .global__item:active .global__itemcontent {
    display: block;
    width: 160px;
    outline: none;
  }
  .global__itemcontent--search {
    margin-top: -5px;
    margin-left: 33px;
    padding-top: 6px;
    &:focus {
      display: block;
      width: 160px;
      outline: none;
    }
    &::placeholder {
      color: $light-blue;
    }
  }

  .nav-outer {
    background-color: rgba(0,139,207,0.8);
    z-index: 1000;
    position: relative;
  }

  .themepic-outer {
    overflow: hidden;
    top: -60px;
    position: relative;
  }
  .themepic-slider-outer {
    top: -60px;
  }
  #main-nav {
    padding: 20px 0 0 0;
    height: 60px!important;
    font-size: 16px;
    float: right;
  }
  #main-nav > ul {
    list-style: none;
    padding: 0;
  }
  #main-nav > ul > li {
    display: inline-block;
    float: left;
  }
  #main-nav > ul > li > a {
    color: #fff;
    margin-left: 7px;
    padding-bottom: 20px;
    &:hover, &.active {
      border-bottom: 5px solid $light-blue;
    }
  }
  #main-nav > ul > li:not(:last-child) > a {
    margin-right: 7px;
  }

  .themepic-slider__item-title {
    font-size: 80px;
    line-height: 80px;
  }

  .section {
    margin-bottom: 100px;
  }

  .section--content {
    background-image: url(../img/welle-content.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    min-height: 900px;
  }

  .section-text--left {
    float: right;
  }

  .section-text--right {
    float: left;
  }

  .section--benefits {
    margin-top: -60px;
  }

  .section__headline--news {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    line-height: 30px;
    background-image: url(../img/lasche.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    padding: 15px 0 33px 0;
    width: 100%;
  }

  .news-box {
    height: 500px;
    width: 50%;
  }

  .news-box__content {
    margin: 100px auto 25px auto;
  }

  .product-list__item {
    background-image: url(../img/schatten-produktbilder.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 20px;
    height: 520px;
  }

  .product-list__item-image {
    margin-bottom: 80px;
  }

  .product-list .slick-dots {
    line-height: 12px!important;
  }

  .product-list .slick-dots li {
    margin: 0 40px;
    z-index: 200;
  }

  .slick-active {
    //productlist
    button:after {
      position: absolute;
      content: '';
      background-color: transparent;
      border: 1px solid $light-blue;
      height: 80px;
      width: 80px;
      border-radius: 100%;
      left: -25px;
      top: -32px;
    }
  }

  .product-list:after {
    content: '';
    position: absolute;
    display: block;
    height: 6px;
    background-color: $light-blue;
    margin-top: 11px;
    left: 20px;
    right: 20px;
  }

  .footer {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .footer-line {
    height: 70px;
  }

  .footer-line__nav {
    float: right;
  }

  .rfq {
    position: fixed;
    right: -201px;
    top: 400px;
    transition: all 0.5s ease;
  }

  .rfq--visible {
    display: inline-block;
    right: 0;
    z-index: 500;
  }

  .section--benefits {
    height: 720px;
    padding-top: 120px;
    .section__headline {
      margin-bottom: 100px;
    }
  }

  .product__col {
    width: 50%;
    float: left;
  }

  .reference__gallery-item {
    height: 300px;
  }

  .reference__products {
    column-count: 2;
    column-gap: 40px;
  }

  .section-text--left {
    padding-left: 20px;
  }
  .section-text--right {
    padding-right: 20px;
  }

  .reference-box {
    width: 50%;
    min-height: 350px;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {
  .randleiste-oben {
    min-height: 80px;
  }
  #main-nav {
    padding-top: 16px;
    font-size: 20px;
  }
  #main-nav > ul > li > a {
    margin-left: 15px;
    //padding-bottom: 19px;
    padding-bottom: 18px;
  }
  #main-nav > ul > li:not(:last-child) > a {
    margin-right: 15px;
  }
  .news-box__content {
    display: none;
  }
  .reference__gallery-item {
    height: 400px;
  }
  .search-template {
    padding: 0 90px;
  }

}

@media (max-width: 1199px) {
  .themepic-outer.start {
    background-image: url(../img/himmel-titelbild.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    //padding-bottom: 20px;
    padding-bottom: 50px;
  }
  //.themepic-outer.folge {
  //  padding-bottom: 0;
  //}
}

@media (max-width: 1199px) and (min-width: 768px) {
  .themepic-outer:not(.folge) {
    padding-top: 120px;
  }
}


/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  //#main-nav .level1 a:hover ~ .level2 {
  //  display: block!important;
  //}

  #main-nav .level1 li a:hover ~ .level2, .level2:hover {
    display: block!important;
  }

  #main-nav ul.level1 > li a {
    position: relative;
  }

  #main-nav ul.level2 {
    position: absolute;
    list-style-type: none;
    padding: 16px 40px 30px 40px;
    background-color: rgba(0, 139, 207, 0.8);
    left: 50%;
    transform: translateX(-50%);
    border-top: solid 58px transparent;
    background-clip: padding-box;
    width: 380px;
    &:before {
      content: '';
      display: block;
      height: 23.5px;
      background-image: url(../img/navi-welle.svg);
      background-repeat: no-repeat;
      //background-position: top center;
      //background-size: 100%;
      opacity: 0.8;
      width: 100%;
      position: absolute;
      left:0;
      top:-24px;
    }

  }

  #main-nav ul.level2 a {
    color: #fff;
    font-size: 17px;
    margin-bottom: 10px;
    white-space: nowrap;
    display: block;
    &:hover, &.active {
      color: $light-blue;
    }
  }

  .level3 {
    display: none;
  }

  .themepic-outer.start {
    min-height: 770px;
  }
  .background-waves {
    background-image: url(../img/doppelwellen-startseite-neu.png);
    background-repeat: no-repeat;
    background-position: center center;
    height: 770px;
    z-index: 50;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-size: cover;
  }

  .background-waves-folge {
    background-image: url(../img/welle-oben-header.svg);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    height: 770px;
    z-index: 50;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .background-waves-folge--products {
    top: -60px;
    max-height: 510px;
  }

  //.themepic {
  //  background-position: center center;
  //  background-repeat: no-repeat;
  //  background-size: cover;
  //}
  .themepic.folge {
    min-height: 770px;
  }
  .themepic.video {
    display: block;
    //width: 100%;
    //margin-top: -60px;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }
  .themepic.video-fallback {
    display: none;
  }
  .themepic-slider {
    z-index: 900;
    margin: 400px auto 0 auto;
  }
  .themepic-slider-outer .slick-dots {
    bottom: 30px;
  }
  .icon-stage {
    min-height: 725px;
  }
  .footer {
    height: 580px;
    padding-top: 200px;
  }
  .reference__gallery-item {
    height: 500px;
  }
  .section-text--left {
    padding-right: 50px;
  }
  .section-text--right {
    padding-left: 50px;
  }

  .reference-box {
    width: 33.333333%;
    min-height: 465px;
  }

  .reference-box__content {
    display: none;
    margin-top: 80px;
  }

  .logo-small.visible {
    display: inline-block;
    //opacity: 1;
    animation: fadeIn 1s;
    position: relative;
    //left: -32px;
    //left: -58px;
    left: -53px;
  }
  .navbar-fixed {
    position: fixed;
    top: 0;
    z-index: 1500;
    width: 100%;
  }
}