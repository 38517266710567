//
// Custom mobile nav (unlimited subnavs possible)
// --------------------------------------------------

@media (max-width: $screen-xs-max) {

  .nav-container {
    padding: 0;
  }

  .navbar-toggle {
    background-color: $brand-primary;
    margin: 7px 0 0 0;
    border: none;
    border-radius: 0;
    width: 55px;
    height: 33px;
    padding: 0 13px;
    float: right;
    //border-bottom: 1px solid #fff;
    .icon-bar {
      background-color: #fff;
      width: 30px;
    }
    /*&:hover, &:focus {
      background-color: $brand-grey;
    }*/
  }

  .navbar-toggle[aria-expanded="true"] {
    .icon-bar--one {
      transform: rotate(45deg) translate(6px, 2px);
      width: 25px;
    }
    .icon-bar--two {
      opacity: 0;
    }
    .icon-bar--three {
      transform: rotate(-45deg) translate(6px, -3px);
      width: 25px;
    }
  }

  .navbar-toggle[aria-expanded="true"] .icon-bar {
    transition: .20s;
  }

  #main-nav {
    border-color: transparent;
    margin-right: 0;
    padding-right: 0;
    border-top: none;
    border-bottom: 1px solid #fff;
  }

  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  #main-nav ul {
    list-style-type: none;
  }

  #main-nav ul {
    background-color: lighten($light-blue, 15%);
    //background: #f5f6f7;
    position: relative;
    float: none;
    overflow: hidden;
    font-size: 12px;
  }

  #main-nav ul li {
    position: relative;
    border-top: 1px solid #fff;
  }

  #main-nav ul li a {
    color: $dark-blue;
    &:hover, &:focus, &:active {
      color: $brand-primary!important;
    }
  }

  .dropdown-toggle {
    border-bottom: none!important;
  }

  #main-nav > ul > li:first-of-type {
    border: none;
  }

  #main-nav ul li ul {
    padding: 0;
  }

  #main-nav > ul > li {
    padding: 0;
  }

  #main-nav > ul > li a {
    padding: 8px 80px 8px 10px;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }

  #main-nav ul.level1 {
    padding: 0;
    margin-bottom: 0;
  }

  #main-nav ul.level2 {
    //background-color: #ebeced;
    background-color: lighten($light-blue, 10%);
    display: none;
  }

  #main-nav ul.level3 {
    //background-color: #e2e3e4;
    background-color: lighten($light-blue, 5%);
    display: none;
  }

  #main-nav ul.level4 {
    ///background-color: #d7d8d9;
    background-color: $light-blue;
    display: none;
  }

  #main-nav ul.level2 a {
    padding-left: 20px;
  }

  #main-nav ul.level3 a {
    padding-left: 30px;
  }

  #main-nav ul.level4 a {
    padding-left: 40px;
  }

  .icon-unfold:before {
    font-family: 'FontAwesome';
    content: "\f105";
    color: #fff;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px 9px;
    width: 55px;
    height: 37px;
    text-align: center;
    background-color: $brand-primary;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    z-index: 1000;
  }

  .icon-unfold--down:before {
    content: "\f107";
  }

}